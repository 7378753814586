import { Environment } from '@worxs/types/environment';
import { Injectable } from '@angular/core';
import { environment } from './environment';

@Injectable({ providedIn: 'root' })
export class EnvironmentClass implements Environment {
  production = false;
  apiUrl: string;
  arbejdsliv: Environment['arbejdsliv']
  shopName = "academy";

  worxs: {
    cmsUrl: string;
  };

  constructor() {
    this.apiUrl = environment.apiEndpoint;

    this.worxs = {
      cmsUrl: environment.backendEndpoint,
    };

    this.googleTagManagerId = environment.googleTagManagerContainerId;

    this.arbejdsliv = {
      adminFirmId: 9999,
      cmsUrl: 'https://cms.arbejdsliv.dk/api',
      googleTagManagerContainerId: 'GTM-XXXXXXX'
    };
  }

  googleTagManagerId: string | null;
}
