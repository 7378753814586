import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {MenuItem, NavigationMenu, NavigationMenuCollection} from "@strapi/types/navigation-menu";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StrapiNavigationService {
  private menus$: BehaviorSubject<NavigationMenuCollection> = new BehaviorSubject<NavigationMenuCollection>([]);

  constructor(
    private http: HttpClient
  ) {

  }

  fetchNavigation() {
    const url = environment.backendEndpoint + '/navigation';

    return this.http.get<NavigationMenuCollection>(url)
      .pipe(
        map(response => this.attachRenderObservable(response)),
        tap(response => this.menus$.next(response))
      )
  }

  private attachRenderObservable(collection: NavigationMenu[]) {
    collection.map(menu => {
      const url = environment.backendEndpoint + '/navigation/render/' + menu.id;
      menu.render$ = this.http.get<MenuItem[]>(url);
    })

    return collection;
  }
}
