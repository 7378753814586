@if (menuItems) {
  <div #dropdown="ngbDropdown" ngbDropdown (mouseenter)="onHover($event, dropdown)"
       (mouseleave)="onHover($event, dropdown)">
    <button ngbDropdownAnchor (focus)="dropdown.open()">
      {{ label }} <i class="bi bi-chevron-down"></i>
    </button>

    <div ngbDropdownMenu>
      @for (menuItem of menuItems; track $index) {
        <a class="dropdown-item"
           routerLink="{{menuItem.path}}" ngbDropdownItem routerLinkActive="active">
          {{ menuItem.title }}
        </a>
      }
    </div>
  </div>
}

@if (megamenu) {
  <div #menu="appMegamenu" appMegamenu (mouseenter)="onHover($event, menu)" (mouseleave)="onHover($event, menu)">
    <a class="anchor">
      {{ label }} <i class="bi bi-chevron-down"></i>
    </a>

    <div class="content" [innerHTML]="megamenu.content"></div>
  </div>
}
