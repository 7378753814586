import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appMegamenu]',
  exportAs: 'appMegamenu',
  standalone: true
})
export class MegamenuDirective {

  constructor(
    private elementRef: ElementRef
  ) {
    this.elementRef.nativeElement.classList.add('megamenu');
    this.elementRef.nativeElement.setAttribute('aria-expanded', 'false');
  }

  open(): void {
    this.elementRef.nativeElement.classList.add('open');
    this.elementRef.nativeElement.setAttribute('aria-expanded', 'true');
  }

  close(): void {
    this.elementRef.nativeElement.classList.remove('open');
    this.elementRef.nativeElement.setAttribute('aria-expanded', 'false');
  }

  toggle(): void {
    this.elementRef.nativeElement.classList.toggleClass('open');
  }

}
