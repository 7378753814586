import { HttpInterceptorFn } from '@angular/common/http';
import {inject} from "@angular/core";
import {ENVIRONMENT_TOKEN} from "@worxs/types/environment";

/**
 * HostInterceptorFn
 * will intercept all http requests and prepend a hostname to the request url if it is not already present.
 *
 * @param req
 * @param next
 */
export const hostInterceptorFn: HttpInterceptorFn = (req, next) => {
  const env = inject(ENVIRONMENT_TOKEN);

  if (!req.url.startsWith('http')) {
    req = req.clone({
      url: env.apiUrl + '/' + req.url
    });
  }

  return next(req);
};
