<div class="contact-card">
  <div class="row d-flex justify-content-end">
    @if (contact) {
      <div class="col-xs-11 col-xs-offset-1 col-md-6 col-md-offset-2 col-lg-4 landscape-card px-3 py-3">
        <div>
          <span class="header">Kontakt</span>
        </div>

        <div class="mt-2">
          Tlf.: {{ contact.attributes.phone_number }}
          <br>
          <a href='mailto:{{ contact.attributes.email }}'>{{ contact.attributes.email }}</a>
        </div>

        <div class="mt-3">
          {{ contact.attributes.address }}
          <br>
          {{ contact.attributes.postal_code }} {{ contact.attributes.city }}
        </div>

        <div class="mt-3">
          CVR: {{ contact.attributes.cvr }}
        </div>
      </div>

      <div class="col-6 portrait-card">
        <div class="row images">
          @for (image of supporterImages; let i = $index; track i) {
            <div class="col-5 image-{{ i + 1 }}">
              <div class="portrait-card-image" style="background-image: url('{{ image }}');"></div>
            </div>
          }
        </div>

        <div class="text">
          <div style="font-weight: bold; font-size: 26px; margin-bottom: 20px;">{{ contact.attributes.card_title }}</div>

          <p>
            {{ contact.attributes.card_text }}
          </p>

          @if (contact) {
            <a href="tel:{{ contact.attributes.phone_number }}" class="btn btn-block btn-secondary phone"
               style="line-height: 40px">
              {{ contact.attributes.phone_number }}
              <span style="padding-left: 1em"><img ngSrc="assets/images/right-arrow.svg" alt="Right arrow" height="11" width="17"></span>
            </a>
          }

        </div>
      </div>
    }
  </div>
</div>
