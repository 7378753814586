import {ApplicationRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {globalAnimations} from './animations';
import {Meta} from '@angular/platform-browser';
import {HeaderComponent} from "@app/components/header/header.component";
import {FooterComponent} from "@app/components/footer/footer.component";
import {MatomoTracker} from "ngx-matomo-client";
import {filter} from "rxjs/operators";
import {GoogleTagManagerService} from "@shared/common/services/google-tag-manager/google-tag-manager.service";

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  imports: [
    HeaderComponent,
    FooterComponent,
    RouterOutlet
  ],
  animations: [
    globalAnimations
  ]
})
export class AppComponent implements OnInit {
  title = 'worxs-frontend';

  constructor(
    meta: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    private appRef: ApplicationRef,
    private matomoTracker:MatomoTracker,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    meta.addTag({name: 'author', content: 'WORXS A/S'});
  }

  ngOnInit() {
    this.gtmService.addGtmToDom();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.gtmService.pushTag({event: 'page', pageName: this.router.url});
      this.matomoTracker.trackPageView();
    });
  }

  prepareRoute(): void {
    return;
    // return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

}
