import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTranslateService } from '../services/custom-translate.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard  {
  constructor(
    private customTranslateService: CustomTranslateService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const languageCode = this.customTranslateService.getLanguageCode();

    if (languageCode === CustomTranslateService.danish) {
      return true;
    }

    this.router.navigate(['/log-ind']);
    return false;
  }
}
