import {Component, OnInit} from '@angular/core';
import {Contact} from '@worxs/cms/types/contact';
import {SingleTypesService} from 'src/app/services/single-types.service';
import {environment} from 'src/environments/environment';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.css'],
  standalone: true,
  imports: [
    NgOptimizedImage

  ]
})
export class ContactCardComponent implements OnInit {

  backendEndpoint: string = environment.backendEndpoint;

  contact?: Contact;
  supporterImages: string[] = [];

  constructor(
    public singleTypesService: SingleTypesService
  ) { }

  ngOnInit(): void {
    this.getContact();
  }

  getContact(): void {
    this.singleTypesService.getContact().subscribe(contact => {
      this.contact = contact;
      const images = this.contact.attributes.support_persons?.data;

      if (!images) {
        this.pushFallbackImages();
      }

      if (!Array.isArray(images)) {
        this.supporterImages.push(`assets/images/office-woman-${this.supporterImages.length + 2}-stock.jpg`);
      } else {
        images.forEach((image) => {
          if (this.supporterImages.length < 2) {
            if (image.attributes && image.attributes.formats.small) {
              this.supporterImages.push(environment.mediaEndpoint + image.attributes.formats.small.url);
            }
          }
        });
      }
    });
  }

  private pushFallbackImages() {
    this.supporterImages.push(`assets/images/office-woman-${this.supporterImages.length + 1}-stock.jpg`);
    this.supporterImages.push(`assets/images/office-woman-${this.supporterImages.length + 2}-stock.jpg`);
  }
}
