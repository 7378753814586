@if (!hidden) {
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="logo-container">
            <img
              src="assets/images/worxs-logo-white-30px.png"
              alt="White WORXS logo"
            />
          </div>

          <div class="row">
            <div class="col-4">
              <ul>
                <li class="my-3 footer-h">
                  <a routerLink="/losninger">Løsninger</a>
                </li>

                @for(service of services; track $index) {
                  <li class="my-3">
                    <a routerLink="/losninger/{{ service.attributes.slug }}">{{
                        service.attributes.title
                      }}</a>
                  </li>
                }
              </ul>
            </div>

            @if (articles && articles.length) {
              <div class="col-4">
                <ul>
                  <li class="my-3 footer-h">
                    <a routerLink="/videnscenter">Videnscenter</a>
                  </li>

                  @for(articleType of articleTypes; track $index) {
                    <li class="my-3">
                      <a
                        [routerLink]="['/videnscenter']"
                        [queryParams]="{ articleType: articleType.id }"
                      >{{ articleType.attributes.name }}</a
                      >
                    </li>
                  }
                </ul>
              </div>
            }


            <div class="col-4">
              <ul>
                <li class="my-3 footer-h">
                  <a routerLink="/akademi">Akademi</a>
                </li>

                <li class="my-3 footer-h">
                  <a routerLink="/webinarer">Webinarer</a>
                </li>

                <li class="my-3 footer-h">
                  <a routerLink="/om-worxs">Om WORXS</a>
                </li>

                <li class="my-3 footer-h">
                  <a routerLink="/kontakt">Kontakt</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <app-contact-card></app-contact-card>
        </div>
      </div>

      <div
        class="d-flex justify-content-between align-content-center mt-5 bottom"
      >
        <div class="legal">
          <ul>
            <li><a routerLink="/privatlivspolitik">Privatlivspolitik</a></li>
            <li><a routerLink="/cookie-politik">Cookie politik</a></li>
          </ul>
        </div>

        <app-so-me [background]="'light'" [buttonsOnly]="true"></app-so-me>
      </div>
    </div>
  </div>
}
